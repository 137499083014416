<template>
  <v-container>
    <v-card class="elevation-0">
      <!-- Week name -->
      <v-card-title :style="{ wordBreak: 'break-word' }">{{ cat3 }}</v-card-title>

      <!-- Data table -->
      <v-data-table :headers="headers" :items="camps" hide-default-footer :items-per-page="-1">
        <!-- Name column -->
        <template v-slot:item.name="{ item }">
          <ColumnName v-bind="item" />
        </template>

        <!-- Age column -->
        <template v-slot:item.minAge="{ item }">
          <ColumnAge v-bind="item" />
        </template>

        <!-- Date column -->
        <template v-slot:item.startDate="{ item }">
          <ColumnDate v-bind="item" />
        </template>

        <!-- Time column -->
        <template v-slot:item.startTime="{ item }">
          <ColumnTime v-bind="item" />
        </template>

        <!-- Fee column -->
        <template v-slot:item.fee="{ item }">
          <ColumnFee v-bind="item" />
        </template>

        <!-- Registration button column -->
        <template v-slot:item.registrationUrl="{ item }">
          <ColumnRegistration v-bind="item" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import ColumnName from './ColumnName'
import ColumnAge from './ColumnAge'
import ColumnDate from './ColumnDate'
import ColumnTime from './ColumnTime'
import ColumnFee from './ColumnFee'
import ColumnRegistration from './ColumnRegistration'

export default {
  components: {
    ColumnName,
    ColumnAge,
    ColumnDate,
    ColumnTime,
    ColumnFee,
    ColumnRegistration,
  },
  props: {
    cat3: String,
    camps: Array,
  },
  data() {
    // Generate table headers
    const headers = [
      { text: 'Camp', value: 'name' },
      { text: 'Gender', value: 'gender' },
      { text: 'Ages', value: 'minAge' },
      { text: 'Dates', value: 'startDate' },
      { text: 'Times', value: 'startTime' },
      { text: 'Spots left', value: 'spotsLeft', align: 'center' },
      { text: 'Fee', value: 'fee', align: 'right' },
      { text: 'Registration', value: 'registrationUrl', align: 'right' },
    ]

    return {
      headers,
    }
  },
}
</script>
