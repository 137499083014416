<template>
  <v-container>
    <h3 class="mt-0">Food &amp; Break Time</h3>
    <p>Our facility is totally nut-free. All food brought by campers must be nut-free.</p>
    <p>All food must be brought in a cooler-style lunch bag. Fridge and microwave are not available.</p>
    <p>Only water will be permitted in the gym. Bring a non-spill, unbreakable container for water only. No glass.</p>

    <h3>Attire</h3>
    <p>
      Campers should come dressed in athletic attire. No skirts, hoods, jeans, buttons, clips, zippers, strings, snaps,
      crop tops/sports bras. Socks are permitted only if they have rubber grips on the bottom.
    </p>
    <p>
      Arrive to camp dressed to participate. Limit extra clothing when possible. For younger campers an extra pair of
      underwear and shorts are permitted.
    </p>

    <h3>Personal Items</h3>
    <div style="margin-top: 8px">
      Campers should bring the following to camp each day in a backpack they can carry themselves:
    </div>
    <ul>
      <li>Non-spill water container</li>
      <li>Nut-free lunch and snacks</li>
      <li>Change of clothes and a plastic bag for younger campers that may have bathroom accidents</li>
    </ul>

    <h3>Registration</h3>
    <p>Each camper attending must be registered. All registrations are confirmed at the time of booking.</p>
    <p>
      Campers must be a minimum of 4 years old at the start of their camp week or eligible for Junior Kindergarten this
      September.
    </p>
    <p>We encourage you to use the waitlist, as spots do become available.</p>

    <h3>Fees &amp; Payment</h3>
    <ul>
      <li><strong>5-day camp week</strong>: $275 + HST per child.</li>
      <li>
        All camp registrations will be subject to a non-refundable Gymnastics Ontario insurance fee of ${{
          insuranceFee
        }}
        + HST.
      </li>
      <li>
        Credit card declines or processing errors upon payment will be subject to a non-refundable administration fee of
        $15 + HST.
      </li>
      <li>Debit-style credit cards will not be accepted by the payment system.</li>
    </ul>

    <h3>Cancellations &amp; Refunds</h3>
    <p>
      Cancellations with at least 7 days’ written notice from the start date of the camp week will receive a refund less
      an administration fee of $50 + HST.
    </p>
    <p>
      Cancellations with less than 7 days’ written notice from the start of the camp week will receive a refund of 50%
      of the original camp fees paid.
    </p>
    <p>Camp is non-refundable once the camp week has begun.</p>
    <p>
      Cancellations will be accepted by email only. Refunds will be calculated using the date and time the email was
      received. Cancellations will not be accepted in-person or by voicemail.
    </p>
    <p>Failure to attend a camp day will not constitute withdrawal or notice.</p>
    <p>In any event, the Gymnastics Ontario insurance fee and applicable admin fees are non-refundable.</p>
  </v-container>
</template>

<script>
export default {
  props: {
    insuranceFee: String,
  },
}
</script>

<style scoped>
h3 {
  background-color: var(--v-secondary-base);
  border-radius: 8px;
  margin-top: 36px;
  margin-bottom: 24px;
  width: 300px;
  padding: 8px;
}
ul {
  margin-top: 8px;
}
</style>
