<template>
  <main v-if="camp">
    <Hero
      :title="camp.hero.title"
      :subtitle="camp.hero.subtitle"
      :image="camp.hero.image"
      :position="camp.hero.position"
    />
    <Welcome
      :title="camp.welcome.title"
      :text="camp.welcome.text"
      :button-text="camp.welcome.buttonText"
      :identifier="camp.welcome.identifier"
    />

    <section id="camp-info" class="white--text">
      <SegregatedCampInfo v-if="camp.segregated" />
      <CampInfo :class="{ 'mt-12': camp.segregated }" :insuranceFee="camp.insuranceFee" />
    </section>

    <WeekList :cat1="camp.cat1" :cat3s="camp.cat3s" />

    <Awards />
    <Map />
    <Footer />
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Welcome from '@/components/Welcome'
import WeekList from '@/components/WeekList'
import CampInfo from '@/components/CampInfo'
import Awards from '@/components/Awards'
import Map from '@/components/Map'
import Footer from '@/components/Footer'
import SegregatedCampInfo from '@/components/SegregatedCampInfo'

import CAMPS from '@/assets/camps'

export default {
  components: {
    Hero,
    Welcome,
    WeekList,
    CampInfo,
    SegregatedCampInfo,
    Awards,
    Map,
    Footer,
  },
  data() {
    return {
      camp: null,
    }
  },
  mounted() {
    this.setData(this.$route.params)
  },
  methods: {
    setData(params) {
      // Select new camp based on route params
      const { camp } = params
      this.camp = CAMPS.find(s => s.name === camp)
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.params)
    next()
  },
}
</script>

<style scoped>
#camp-info {
  background-image: url('../assets/black-tile.jpg');
  background-repeat: repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>
