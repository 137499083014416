var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{style:({ wordBreak: 'break-word' })},[_vm._v(_vm._s(_vm.cat3))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.camps,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ColumnName',_vm._b({},'ColumnName',item,false))]}},{key:"item.minAge",fn:function(ref){
var item = ref.item;
return [_c('ColumnAge',_vm._b({},'ColumnAge',item,false))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('ColumnDate',_vm._b({},'ColumnDate',item,false))]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('ColumnTime',_vm._b({},'ColumnTime',item,false))]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('ColumnFee',_vm._b({},'ColumnFee',item,false))]}},{key:"item.registrationUrl",fn:function(ref){
var item = ref.item;
return [_c('ColumnRegistration',_vm._b({},'ColumnRegistration',item,false))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }